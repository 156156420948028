.animText {
  opacity: 0;
  filter: blur(5px);
  transition: all 1s;
}

/* ----------------------------------------------
 * Generated by Animista on 2023-9-25 17:57:37
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-bottom
 * ----------------------------------------
 */

@-webkit-keyframes textAnim {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(100px);
    transform: translateX(100px);
  }
}
@keyframes textAnim {
  0% {
    -webkit-transform: translateX(-100px);
    transform: translateX(-100px);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@-webkit-keyframes scale-in-center {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes scale-in-center {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}

@-webkit-keyframes slide-bottom {
  0% {
    -webkit-transform: translateY(-100px);
    transform: translateY(-100px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
@keyframes slide-bottom {
  0% {
    -webkit-transform: translateY(-100px);
    transform: translateY(-100px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}


@media (prefers-reduced-motion: no-preference) {

  .logoImg {
    -webkit-animation: slide-bottom 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    animation: slide-bottom 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  }
  
  .Landing_Image {
    -webkit-animation: scale-in-center 1s cubic-bezier(0.25, 0.46, 0.45, 0.94)
      both;
    animation: scale-in-center 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  }

  .textAnim {
    -webkit-animation: textAnim 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    animation: textAnim 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    opacity: 1;
    filter: blur(0);
  }
}
