* {
    margin: 0;
    padding: 0;
    /* width: 0; */
}

#root {
    min-width: 280px;
}

.MainBody{
    background-color: #f1f1f1;
    /* background-color: #58cf9d; */
    height: 100%;
    /* width: 100vw; */
}

.SubBody{
    padding-top: 3rem;;
}

.FirstContainer {
    background-color: white;
    border-radius: 15px;
}

.FrameContainer {
    width: 230mm;
    height: 297mm;
    margin: 0 auto;
    position: relative;
}

/* .FrameContainer:hover {
  .CustomizeButton {
    visibility: visible;
    opacity: 1;
    transition: all ease-in-out 0.3s;
  }
} */
.FrameContainer:hover .CustomizeButton {
    visibility: visible;
    opacity: 1;
    transition: all ease-in-out 0.3s;
  }

 #inlineFrameExample {
    width: 230mm;
    height: 297mm;
}

.CustomizeButton{
    border: 0;
    position: absolute;
    top: 20px;
    right: 20px;
    padding: 10px 20px;
    background: url("");
    max-width: 100%;
    cursor: pointer;
    opacity: 0;
    visibility: hidden;
    transition: all ease-in-out 0.3s;
}

.FC1 {
    padding: 1rem;
}

.ProductTitle{
    font-size: 19px;
    font-weight: bold;
    text-align: center;
}

.ProductText{
    font-size: 0.8rem;
    text-align: center;
    margin-left: 0.75rem;
    margin-right: 0.75rem;
}

.FC2{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
}

.ProductIconContainer{
    padding: 1rem;
    display: flex;
    justify-content: center;
}

.ProductImages{
    margin-left: auto;
    margin-right: auto;
}

.SecondContainer{
    padding: 0.75rem;
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
}

.SC1{
    margin-bottom: 0.75rem;
}

.ContactUS{
    color: #686868;
    text-align: center;
    font-style: italic;
    padding-bottom: 6px;
}

.Email{
    color: #2868d6;
    text-align: center;
    padding-bottom: 6px;
}

.Number{
    text-align: center;
    font-weight: 300;
    padding-bottom: 6px;
}

.SC2{
    display: flex;
    align-items: center;
    flex-direction: column;
}

.ViknCodesLogo{
    margin-left: auto;
    margin-right: auto;
    height: 2.25rem;
}

.ViknCodesLink{
    text-align: right;
    text-decoration-line: underline;
    color: #007BCE;
}

.PDFContainer {
    padding: 2.5rem;    
    background-color: white;   
    border-radius: 15px;
    margin-bottom: 4rem;
    overflow-x: auto;
}

@media screen and (min-width: 740px) {
    .FirstContainer {
        background-color: white;
        display: flex;
        justify-content: space-between;
        border-radius: 30px;
        padding: 1.5rem;
    }
    .SubBody{
        padding-top: 3rem;
        margin-right: 4rem;
        margin-left:  4rem;
    }
    .FC2{
        width: 20rem;
    }
    .ProductTitle{
        font-weight: bold;
        font-size: 25px;
        text-align: left;
        margin-bottom: 10px;
    }
    .ProductText{
        text-align: left;
        margin-left: 0;
        margin-right: 0;
        width: 17rem;
        font-size: 0.9rem;
    }
    .PDFContainer {
        overflow-x: auto;
        /* overflow-y: hidden; */
    }
    .SecondContainer{
        display: flex;
        justify-content: space-between;
    }
    .SC1{
        margin-bottom: 0;
    }
    .SC2{
        display: flex;
        align-items: flex-end;
        flex-direction: column;
    }
    .ContactUS {
        text-align: left;
    }
    .Email{
        text-align: left;
    } 
    .NumberContainer{
        display: flex;
    }
    .Number {    
        text-align: left;
    }
}

@media screen and (min-width: 992px) {
    .SubBody{
        margin-left:  10rem;
        margin-right: 10rem;
    }
    .ProductText{
        width: 16rem;
        font-size: 1rem;
    }
}

@media only screen and (min-width: 1200px) {
    .SubBody{
        margin-left:  15rem;
        margin-right: 15rem;
    }
}