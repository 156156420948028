@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.mainContainer{
    font-family: 'Poppins';font-family: 'Poppins';
}

.headingContainer{
    display: flex;
    justify-content: space-between;
    padding: 16px;
    align-items: center;
}
.headingContainer .languageButton{
    display: flex;
    gap: 8px;
    font-size: 16px;
}
.headingContainer img{
    height: 80px;
}
.subheadingContainer {
    padding: 16px;
}

.subheadingContainer h1{
    font-family: 'Poppins';
    font-weight: 600;
    padding-bottom: 8px;
}
.subheadingContainer span{
    color: #ED1B24;
    font-family: 'Poppins';
    font-weight: 600;
}

.categoryContainer {
    position: sticky;
    top: 0;
    z-index: 1000; 
    background-color: white;
    padding-bottom: 16px;
  }

  .categoryHeadingContainer{
    padding: 16px;
    display: flex;
    justify-content: space-between;
  }
.seeMoreButton{
    font-size: 16px;
    color: #F25F29;
}
.seeMoreButton:hover{
  cursor: pointer;
}
  
.scrollingContainer {
  display: flex;
  gap: 8px;
  padding-left: 16px;
  overflow-y: scroll; /* Keep scrolling enabled */
  font-size: 16px;
}

/* Hide scroll bar for webkit browsers (Chrome, Safari, Edge) */
.scrollingContainer::-webkit-scrollbar {
  display: none;
}

/* For Firefox */
.scrollingContainer {
  scrollbar-width: none;
}

/* For Internet Explorer */
.scrollingContainer {
  -ms-overflow-style: none;
}

  
  .categoryCard {
    display: flex;
    background-color: #F25F29;
    border-radius: 32px;
    width: fit-content;
    padding: 8px 16px;
    gap: 8px;
    align-items: center;
    font-size: 16px;
    font-weight: 00;
    color: white;
    white-space: nowrap; 
  }
  .categoryCard:hover{
    cursor: pointer;
  }
  
  .categoryCard img{
    width: 30px;
    height: 30px;
    border-radius: 30px;
  }
  .productContainer {
    display: grid;
    grid-template-columns: repeat(auto-fit,minmax(150px, 1fr));
    gap: 16px;
    padding: 16px;
  }
  
  .productCard {
    background-color: #F8F8F8;
    padding: 16px;
    border-radius: 12px;
  }
  .productCard h2 {
    padding-top: 8px;
    /* width: 100px;       Set a fixed width */
    white-space: nowrap; /* Prevent text from wrapping to the next line */
    overflow: hidden;    /* Hide overflowing text */
    text-overflow: ellipsis; /* Show ellipsis (...) for overflowing text */
    font-weight: 600;
}
.productCard h3 {
  padding-top: 8px;
  width: 100px;       /* Set a fixed width */
  white-space: nowrap; /* Prevent text from wrapping to the next line */
  overflow: hidden;    /* Hide overflowing text */
  text-overflow: ellipsis; /* Show ellipsis (...) for overflowing text */
}

.productCard .currency{
  padding-left: 8px;
  color: #ED1B24;
  font-weight: 600;
}
  .productCard:hover{
    cursor: pointer;
  }
  
  .productCard img {
    width: 100%;
    border-radius: 8px;
    height: 120px;
    margin-bottom: 16px;
  }
  
  /* Adjust for small screens (e.g., mobile) */
/* @media (max-width: 500px) {
  .productContainer {
    grid-template-columns: repeat(2, 1fr); 
  }
} */