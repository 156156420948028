@import url("https://fonts.googleapis.com/css2?family=Azeret+Mono:wght@300;400;500;600&family=Bungee&display=swap");

* {
  box-sizing: border-box;
}

/* Position */
.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

/* Dimensions */
.w-100 {
  width: 100% !important;
}

.h-100 {
  height: 100% !important;
}

.h-100vh {
  height: 100vh;
}

.w-100vw {
  width: 100vw;
}

.m-auto {
  margin: auto;
}

.mr-12 {
  margin-right: 12px;
}

.mb-14 {
  margin-bottom: 14px;
}

.mb-24 {
  margin-bottom: 24px;
}

.mx-27 {
  margin-left: 27px;
  margin-right: 27px;
}

.my-35 {
  margin-top: 35px;
  margin-bottom: 35px;
}

/* Display */
.d-block {
  display: block;
}

.d-hidden {
  display: none;
}

.d-flex {
  display: flex;
}

/* Flex Property */
.align-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.justify-spaceBetween {
  justify-content: space-between;
}

/* Color */

.bg-black {
  background-color: #00192a;
}

.bg-darkBlue {
  background-color: #002944;
}

.bg-blue {
  background-color: #0c8dff !important;
}

.bg-lightBlue {
  background-color: #e6f4ff;
}

.bg-white {
  background-color: white;
}

.bg-snowWhite {
  background-color: #f0f9ff;
}

.font-darkBlue {
  color: #00192a;
}

.font-blue2 {
  color: #4d7a98;
}

.font-lightBlue {
  color: #34f8ff;
}

.font-blue {
  color: #0c8dff;
}

.font-steelBlue {
  color: #37617e;
}

.font-black {
  color: black;
}

.font-white {
  color: #ffffff;
}

/* Fonts */
.font-bungee {
  font-family: "Bungee", cursive;
}

.font-azeret {
  font-family: "Azeret Mono", monospace;
}

.font-14 {
  font-size: 14px;
}

.font-23 {
  font-size: 23px;
}

.font-31 {
  font-size: 31px;
}

.text-light {
  font-weight: 300;
}

.text-regular {
  font-weight: 400;
}

.text-medium {
  font-weight: 500;
}

.text-semibold {
  font-weight: 600;
}

.text-center {
  text-align: center;
}

.line-height {
  line-height: 1.2;
}

.container {
  width: 500px;
  height: 66px;
}

.cursor-pointer {
  cursor: pointer;
}

/* Animation */
@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

/* Our Style */

/* .imageContainer {
  display: flex;
} */

.lButton {
  /* animation: 1s ease-out 0s 1 slideInFromLeft; */
  background-color: #001d30;
  /* height: 44px; */
  width: 171px;
  padding: 15px 25px;
  border-radius: 5px;
  color: #ffffff;
  border: none;
  cursor: pointer;
}

.landingPageTitles {
  padding: 0px 50px;
}

.firstSection {
  padding-top: 40px;
}

.logoContainer {
  position: relative;
  bottom: 0px;
  height: 60%;
  /* background-image: url("../images/LandingPage_Assets/Group3013.svg"); */
}

.unPosFirst {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 600px;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
}

.unpositinedElements {
  display: none;
}

.fsimg1 {
  /* position: absolute; */
  /* left: 420px;
  height: 46px; */
  overflow-x: hidden;
}

.fsimg2 {
  /* position: absolute; */
  /* right: 413px;
  height: 46px; */
  overflow-x: hidden;
}

/* .imageContainer { */
/* } */

.Landing_Image {
  position: absolute;
  /* max-height: 425px; */
  max-height: 98%;
  width: 100vw;
  bottom: 0px;
  z-index: -1;
  /* left: -55px; */
  /* overflow: hidden; */
}

.fsTitle {
  max-width: 734px;
  margin-left: auto;
  margin-right: auto;
}

.heading {
  position: fixed;
  width: 100%;
  box-shadow: 0px 3px 6px #00000029;
  background-color: white;
  padding: 15px 70px;
  z-index: 999;
}

.headerButton {
  /* animation: 1s ease-out 0s 1 slideInFromLeft; */
  background-color: #001d30;
  font-family: Azeret Mono;
  font-size: 12px;
  width: 89px;
  padding: 9px 17px;
  border-radius: 5px;
  color: #ffffff;
  border: none;
  cursor: pointer;
}

.secondSection {
  padding-left: 32px;
  padding-right: 32px;
  padding-top: 66px;
  padding-bottom: 66px;
}

.error-text {
  color: red;
}

.inputBoxErrortext {
  border: 1px solid red !important;
}

.inputBox {
  /* height: 45px; */
  padding: 17px;
  border-radius: 5px;
  border: none;
  outline: none;
  margin-top: 4px;
  margin-bottom: 16px;
  box-shadow: 0 0 0 rgba(0, 0, 0, 0); /* Initial shadow with no blur */
  transition: box-shadow 0.3s ease; /* Add a smooth transition effect */
}

.inputBox:focus {
  box-shadow: 0px 7px 0px #0c8dff; /* Adjust the shadow properties as needed */
  border: none;
}

.thirdSection {
  padding: 40px;
}

.tsholder {
  max-width: 1440px;
  margin-right: auto;
  margin-left: auto;
}

.chooseBox {
  margin-bottom: 32px;
}

.chooseOption {
  margin-bottom: 31px;
}

.chooseCard {
  background-color: #0b3957;
  border-radius: 13px;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 35px;
  padding-bottom: 35px;
  margin-bottom: 12px;
}

.fourthSection {
  padding-bottom: 35px;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 35px;
  max-width: 1026px;
  margin: auto;
}

/* .featureSliderWrapper {
  min-width: 280px;
  overflow-x: scroll;
} */

.featureSlider {
  margin-bottom: 34px;
  min-width: 280px;
  overflow-x: scroll;
}

.featuresliper {
  margin-bottom: 41px;
}

.featureCard {
  border-radius: 13px;
  padding-top: 45px;
  padding-bottom: 40px;
  padding-left: 27px;
  padding-right: 27px;
  width: 309.44px;
  /* height: 391px; */
}

.featureCard:not(:last-child) {
  margin-right: 15px;
}

.featureCard > p {
  min-width: 300px;
}

.fifthSection {
  padding-bottom: 35px;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 35px;
}

.fsholder {
  max-width: 1284px;
  margin-left: auto;
  margin-right: auto;
}

.testimoniSlider {
  display: none;
  /* overflow: auto;
  padding-top: 25px; */
  /* flex-direction: column;
  align-items: center; */
}

.testimoniCard {
  padding-top: 35px;
  padding-bottom: 35px;
  padding-left: 29px;
  padding-right: 29px;
  border-radius: 13px;
  margin-bottom: 40px;
  max-width: 600px;
  min-width: 343.5px;
}

.testimoniCard:not(:last-child) {
  margin-right: 20px;
}

.testmoniesContainer {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
}

.comaImage {
  position: absolute;
  top: -22px;
  right: 35px;
}

.sixthSection {
  padding-top: 35px;
  padding-bottom: 35px;
  padding-left: 23px;
  padding-right: 23px;
}

.sSparagraph {
  max-width: 591px;
  margin-left: auto;
  margin-right: auto;
}

.seventhSection {
  padding-top: 35px;
  padding-bottom: 35px;
  padding-left: 23px;
  padding-right: 23px;
}

footer {
  background-color: #00192a;
  padding-top: 42px;
  padding-left: 29px;
  padding-right: 29px;
  padding-bottom: 35px;
}

footer ul > li:not(:last-child) {
  margin-right: 10px;
}

@media screen and (min-width: 640px) {
  .font-14 {
    font-size: 18px;
  }

  .font-23 {
    font-size: 29px;
  }

  .font-31 {
    font-size: 37px;
  }

  .lButton {
    width: 220px;
  }
}

@media screen and (min-width: 768px) {
  /* .font-14 {
        font-size: 24px;
    } */

  /* .font-23 {
      font-size: 34px;
    } */

  /* .font-31 {
        font-size: 31px;
    } */

  .fsimg1 {
    width: 88px;
  }

  .fsimg2 {
    width: 112px;
  }

  .unpositinedElements {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: space-between;
  }

  .chooseOption {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
  }

  .fscImg1 {
    position: absolute;
    top: -38px;
    left: 4px;
  }

  .fscImg2 {
    position: absolute;
    top: 22px;
    left: 65px;
  }

  .fscImg3 {
    position: absolute;
    top: 80px;
    left: -6px;
  }

  .fscImg4 {
    position: absolute;
    top: 66px;
    left: 131px;
  }

  .fscImg5 {
    position: absolute;
    top: -32px;
    right: 3px;
  }

  .fscImg6 {
    position: absolute;
    top: 22px;
    right: 22px;
  }

  .fscImg7 {
    position: absolute;
    top: 86px;
    right: 145px;
  }

  .sscImg1 {
    position: absolute;
    top: -101px;
    left: 19px;
  }

  .sscImg2 {
    position: absolute;
    top: -44px;
    left: 61px;
    /* bottom: 0px;
    right: 0px; */
  }

  .sscImg3 {
    position: absolute;
    top: 28px;
    left: 5px;
  }

  .sscImg4 {
    position: absolute;
    top: 23px;
    left: 161px;
  }

  .sscImg5 {
    position: absolute;
    top: -111px;
    right: 1px;
    /* bottom: 0px;
    right: 0px; */
  }

  .sscImg6 {
    position: absolute;
    top: -54px;
    /* left: 919px; */
    /* bottom: 0px; */
    right: 23px;
  }

  .sscImg7 {
    position: absolute;
    top: 30px;
    /* left: 793px; */
    /* bottom: 0px; */
    right: 154px;
  }

  .chooseCard {
    width: 350px;
  }

  .chooseCard:not(:last-child) {
    margin-right: 15px;
  }

  .featureCard > P {
    font-size: 14px;
    min-width: 0px;
  }
}

@media screen and (min-width: 1024px) {
  /* .font-14 {
        font-size: 24px;
    } */

  .font-23 {
    font-size: 34px;
  }

  /* .font-31 {
      font-size: 31px;
  } */
  .fscImg1 {
    position: absolute;
    top: -38px;
    left: -76px;
  }

  .fscImg2 {
    position: absolute;
    top: 22px;
    left: -5px;
  }

  .fscImg3 {
    position: absolute;
    top: 100px;
    left: -23px;
  }

  .fscImg4 {
    position: absolute;
    top: 88px;
    left: 112px;
  }

  .fscImg5 {
    position: absolute;
    top: -32px;
    right: -74px;
  }

  .fscImg6 {
    position: absolute;
    top: 27px;
    right: -5px;
  }

  .fscImg7 {
    position: absolute;
    top: 95px;
    right: 129px;
  }

  .logoImg {
    margin-bottom: 55px;
  }

  .testimoniCard:not(:last-child) {
    margin-right: 15px;
  }

  .sscImg1 {
    position: absolute;
    top: -101px;
    left: 235px;
  }

  .onlyMobile {
    display: none;
  }

  .sscImg2 {
    position: absolute;
    top: -34px;
    left: 298px;
  }

  .sscImg3 {
    position: absolute;
    top: 34px;
    left: 247px;
  }

  .sscImg4 {
    position: absolute;
    top: 35px;
    left: 409px;
  }

  .sscImg5 {
    position: absolute;
    top: -106px;
    right: 232px;
  }

  .sscImg6 {
    position: absolute;
    top: -54px;
    right: 267px;
  }

  .sscImg7 {
    position: absolute;
    top: 30px;
    right: 417px;
  }

  .logoContainer {
    min-height: 430px;
  }

  .featureSlider {
    /* display: flex;
    justify-content: flex-start; */
    overflow-x: hidden;
  }

  /* .chooseOption {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(1, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
  } */

  .testimoniSlider {
    display: flex;
    /* align-items: center; */
  }
}

@media screen and (min-width: 1152px) {
  /* .font-14 {
        font-size: 14px;
    }
    
    .font-23 {
        font-size: 23px;
    }
    
    .font-31 {
        font-size: 31px;
    } */

  .ssholder {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .ssText {
    text-align: left;
  }

  .ssFirstContner {
    width: 530px;
  }

  .formButton {
    width: 170px !important;
    /* height: 44px !important; */
  }

  .d-hidden {
    display: block;
  }

  /*.formImageContainer {
        width: ;
    } */

  .formImage {
    width: 486px;
    height: 401px;
  }

  .chooseOption {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(1, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
  }

  .chooseCard {
    width: 330px;
  }

  .chooseBox > h1,
  .chooseBox > h2,
  .chooseBox > p {
    text-align: left !important;
  }

  .tstContainer {
    margin-top: 83px;
  }

  .chooseOptionContainer {
    display: flex;
    justify-content: flex-start;
  }

  .fourthSection {
  }

  .mobailCurosel {
    display: none;
    margin-bottom: 52px;
  }

  .fsText {
    text-align: left;
  }

  /* .featureSlider {
    display: flex;
    justify-content: flex-start;
  } */

  .featuresliper {
    display: flex;
    justify-content: flex-start;
  }

  .fifthSecTitle {
    margin-bottom: 50px;
  }

  .sixthSection {
    max-width: 1151px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (min-width: 1372px) {
  .secondSection {
    padding: auto 40px;
  }
  .ssholder {
    margin: auto;
    max-width: 1284px;
  }

  .chooseOption {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(1, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
  }
}

@media screen and (min-width: 1573px) {
  .chooseOption {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(1, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
  }

  .chooseCard {
    width: 370px;
  }

  .chooseOptionContainer {
    display: flex;
    justify-content: flex-start;
  }
}

.sliper {
  width: 300px;
  text-align: center;
  overflow: hidden;
}

.slipers {
  display: flex;

  overflow-x: auto;
  scroll-snap-type: x mandatory;

  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;

  /*
    scroll-snap-points-x: repeat(300px);
    scroll-snap-type: mandatory;
    */
}

.slipers::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
.slipers::-webkit-scrollbar-thumb {
  background: black;
  border-radius: 10px;
}
.slipers::-webkit-scrollbar-track {
  background: transparent;
}
.slipers > div {
  scroll-snap-align: start;
  flex-shrink: 0;
  width: 300px;
  height: 300px;
  margin-right: 50px;
  border-radius: 10px;
  background: #eee;
  transform-origin: center center;
  transform: scale(1);
  transition: transform 0.5s;
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 100px;
}

.slipers > div:target {
  /* transform: scale(0.8); */
}

.author-info {
  background: rgba(0, 0, 0, 0.75);
  color: white;
  padding: 0.75rem;
  text-align: center;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  margin: 0;
}

.author-info a {
  color: white;
}

.sliper > a {
  display: inline-flex;
  width: 1.5rem;
  height: 1.5rem;
  background: white;
  text-decoration: none;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin: 0 0 0.5rem 0;
  position: relative;
}

.sliper > a:active {
  top: 1px;
}

.sliper > a:focus {
  background: #000;
}

/* Don't need button navigation */
@supports (scroll-snap-type) {
  .sliper > a {
    display: none;
  }
}
